.privacy-policy-container {
  max-width: 90%;
  margin: 20px auto;
  padding: 10px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.policy-heading {
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 20px;
}

.policy-content {
  font-size: 16px;
  line-height: 1.5;
  color: #333;
}

.policy-content strong {
  font-weight: bold;
  color: #003B46;
}
.back-button-container{
  margin: 0 auto;
  text-align: center;

}


@media only screen and (max-width: 600px) {
  .back-button {
     bottom: 20px;
     left: 30%;
  }
}