.input-phone-container {
    width: 80%;
    padding-right: 20px;
    margin: 0 auto;
  }
  
  .input-phone-container-hebrew {
    width: 80%;
    padding-right: 50px;
   /* text-align: center; */
  }
  
  .phone-input {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 5px;
    border: 1px solid #4ae9e4;
    font-family: 'Rubik', sans-serif;
    margin: 0 auto;
    font-size: 25px;
  }
  
  @media (max-width: 768px) {
    /* Стили для планшетов */
    .input-container {
      width: 100%;
      padding-right: 0;
    }
  
    .input-container-hebrew {
      width: 100%;
      padding-right: 0;
    }
    
    .phone-input {
      width: 90%;
      margin: 0 auto;
    }
  }
  
  @media (max-width: 480px) {
    /* Стили для телефонов */
    .input-phone-container,
    .input-phone-container-hebrew  {
      width: 80%;
      padding-right: 22px;
    }
    
    .phone-input {
      width: 100%;
      margin: 0 auto;
    }
  }
  