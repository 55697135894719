.scroll-to-top {
    position: fixed;
    bottom: 100px;
    right: 20px;
    z-index: 1000;
}

.scroll-to-top-hebrew {
    position: fixed;
    bottom: 100px;
    left: 20px;
    z-index: 1000;
}


.scroll-button {
    background-color: #003B46;
    color: white;
    border: none;
    padding: 12px;
    border-radius: 50%;
    cursor: pointer;
    transition: opacity 0.3s ease-in-out;
}

.scroll-button:hover {
    background-color: #ff0000;
}

.scroll-button svg {
    display: block;
}
