.footer-container {
  display: flex;
  justify-content: space-around;
  padding: 20px;
  background-color: #dcdce2;
  color: #003B46;
  flex-wrap: wrap;
  align-items: flex-start; /* Поднимает элементы вверх */
}

.footer-section {
  max-width: 400px;
  margin: 10px;
  text-align: center; /* Смена выравнивания текста на левое */
}

.footer-section h4 {
  margin-bottom: 10px;
  font-family: 'Rubik', sans-serif;
  font-size: 1.5rem;
  color: #003B46;
}

.footer-section p {
  margin: 5px 0;
  font-family: 'Rubik', sans-serif;
  font-size: 1rem;
  color: #003B46;
}

.footer-icon {
  margin-right: 8px;
  color: #003B46;
}

.footer-section a {
  text-decoration: none;
  color: #003B46;
  display: flex;
  align-items: center;
  justify-content: center; /* Смена выравнивания на левое */
}

.footer-section a:hover {
  text-decoration: underline;
}

.policy-link {
  cursor: pointer;
  transition: color 0.3s;
  color: #003B46;
}

.policy-link:hover {
  color: #D9B08C;
}

.footer-copyright {
  text-align: center;
  padding: 10px;
  background-color: #003B46;
  color: #FFFFFF;
  font-family: 'Rubik', sans-serif;
  font-size: 1rem;
}

.rtl {
  direction: rtl;
}

.contact-details {
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Смена выравнивания на левое */
  margin-top: -10px; /* Уменьшает расстояние между элементами */
}

.contact-item {
  display: flex;
  align-items: center;
  margin: 5px 0; /* Уменьшает расстояние между элементами */
}

.social-icons {
  display: flex;
  justify-content: center;
  gap: 10px; /* Уменьшает расстояние между иконками */
  margin-top: 10px;
}

.footer-social-icon {
  color: #003B46;
  transition: color 0.3s;
}

.footer-social-icon:hover {
  color: #D9B08C;
}

@media only screen and (max-width: 700px) {
  .footer-container {
      flex-direction: column;
      align-items: center;
  }

  .footer-section {
      max-width: none;
      text-align: center; /* Центровка текста на мобильных устройствах */
  }

  .contact-details {
      align-items: center; /* Центровка элементов на мобильных устройствах */
  }

  .contact-item {
      justify-content: center; /* Центровка элементов на мобильных устройствах */
  }
  .footer-section h4 {
    font-size: 20px;
  }
}
