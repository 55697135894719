 .language-option {
   cursor: pointer;
   color: black;
   background-color: #E1EFF3;
   width: 35px;
   text-align: center;
   height: 45px;
 }

 .language-option :hover {
   color: black;
   font-weight: bold;

 }

 .language-option.selected {
   color: black;

 }

 .language-selector {
   margin-right: 20px;
   display: flex;

 }

 .language-selector-hebrew {
   display: flex;
   justify-content: flex-end;
 }