.nav {
  background-color: white;
  display: flex;
  align-items: center;
  z-index: 9999;
}

.nav a {
  text-decoration: none;
  color: #003B46;
  font-size: 16px;
  margin: 5px;
  font-family: 'Rubik';
}

.nav_hebrew a{
  text-decoration: none;
  color: #003B46;
  font-size: 22px;
  font-style: normal;
  font-family: 'Rubik';
}

.nav a:hover,
.nav_hebrew a:hover {
  color: #4ae9e4;
  font-size: 24px;
}


.company-name{
  font-size: 30px;
  color: #69d5d2;
  font-family: 'Rubik';

}

.navigation-selector {
  display: flex;
  gap: 10px;

}


.burger-menu {
  display: none
}

.burger-menu-data {
  display: none
}


.burger-menu-data a:hover,
.burger-menu-data-hebrew a:hover {
  color: #69d5d2;
  font-weight: bold;
}

.burger-languages,
.burger-menu-container {
  display: none;
}

.current-page {
  color: #69d5d2 !important;
  font-weight: bold !important;
  font-size: 23px !important;

}

.current-page:hover {
  color: #69d5d2;
  min-width: 80px !important;

}

.navigation-exit img {
  vertical-align: middle;

  /* Выравнивание изображения по вертикальному центру */
}

.navigation-exit span {
  vertical-align: middle;
  /* Выравнивание текста по вертикальному центру */
}

.contact-nav-container {
  display: flex;
  margin: 10px;
}

.contact-nav-container-home-screen{
  display: flex;
  flex-direction: row;
  justify-content: center;
  /* padding-top: 10px; */
  /* padding-bottom: 10px; */
  /* margin: 0 auto; */
  /* padding: 40px; */
}



@media only screen and (max-width: 900px) {
  .nav {
    display: block;
    text-align: center;
    flex-wrap: wrap;
  }

  .display-languages{
    /* display: none; */
    margin: auto;
  }

  .nav a {
    font-size: 14px;
  }

  .nav_hebrew {
    font-size: 16px;
  
    /* margin-right: 100px; */
  }

  .contact-nav-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-top: 10px;
    padding-bottom: 10px;

  }
  .contact-nav-container-home-screen{
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-top: 10px;
    padding-bottom: 10px;
    margin: 0 auto;
}

}


@media only screen and (max-width: 670px) {
  .nav {
    display: block;
    
  }

  .nav_hebrew {  
    margin-right: 0px;
  }
  .display-languages{
    display: none;
  }
  .contact-nav-container {
    display: none;
  }

  .burger-menu,
  .burger-menu-container {
    display: flex;
    margin: 8px;
  }

  .burger-menu {
    display: block;
    cursor: pointer;
  }

  .bar {
    width: 25px;
    height: 3px;
    background-color: white;
    margin: 6px 0;
    transition: transform 0.3s, opacity 0.3s;
    
  }

  .open {
    transition: transform 0.3s, opacity 0.3s;
  }

  /* Добавьте новые стили для крестика */
  .bar.open:nth-child(1) {
    transform: rotate(-45deg) translate(-5px, 6px);
  }

  .bar.open:nth-child(2) {
    opacity: 0;
  }

  .bar.open:nth-child(3) {
    transform: rotate(45deg) translate(-5px, -6px);
  }

  .burger-menu-data {
    background-color: white;
    padding: 10px;
    position: absolute;
    top: 100px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    z-index: 9999;
    text-align: left;
    padding-bottom: 40px;

  }

  .burger-menu-data a {
    text-decoration: none;
    color: #003B46;
    font-weight: bold;
    font-size: 16px;

  }

  .burger-menu-company-name{
    font-family: 'Rubik';
    font-size: 25px;
    
  }

  .burger-menu-data-hebrew {
    text-align: right;
  }

  .burger-menu-open .language-selector {
    display: none;
  }

  .navigation-none-container {
    display: none;
  }

  .company-name {
    display: none;
  }

  .burger-menu-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 95%;
    align-items: center;

  }

  .burger-languages {
    display: flex;
    flex-direction: column;
    /* background-color: #003B46; */
    align-items: center;
  }

  .company-name-burger {
    color: white;
    font-size: 20px;
    text-decoration: none;
    font-family: 'Rubik';

  }

  .burger-links {
    text-align: center;
    margin-top: 10px;

  }

  .burger-links a {
    text-decoration: none;
    color: white;
    font-size: 16px;
    margin: 4px;
  }

  .current-page {
    display: none;
  }

  .phone-style{
    display: none;
  }
  .contact-nav-container-home-screen{
   padding-left: 20px;
  }
}