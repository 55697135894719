body {
  margin: 0;
  padding: 0;
}

button {
  -webkit-appearance: #dcdce2; /* Removes the default appearance */
  -webkit-tap-highlight-color: #dcdce2; /* Removes the blue highlight on tap */
  outline: none; /* Removes the outline */
  border: none; /* Removes the border */
  background: #dcdce2; /* Removes the background */
}

.App {
  background-color: #dcdce2;
} 

@font-face {
  font-family: 'Rubik';
  src: url('/src/Components/Receipt/Fonts/Rubik-Regular.ttf') format('truetype');
}

