/* Анимация для колонок */
@keyframes slideInFromLeft {
  0% {
      transform: translateX(-100%);
      opacity: 0;
  }
  100% {
      transform: translateX(0);
      opacity: 1;
  }
}

@keyframes slideInFromRight {
  0% {
      transform: translateX(100%);
      opacity: 0;
  }
  100% {
      transform: translateX(0);
      opacity: 1;
  }
}

.signup-card-left {
  animation: slideInFromLeft 1s ease-out;
}

.signup-card-right {
  animation: slideInFromRight 1s ease-out;
}

.signup-container {
    position: relative;
    padding: 20px;
    background-color: #f9f9f9;
    text-align: center;
  }

  .signup-container::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('../../Images/many_people.jpg'); /* Путь к вашему фоновому изображению */
    opacity: 0.5; /* Прозрачность изображения */
  }
  
  
  .signup-title {
    margin-bottom: 20px;
  }

  .signup-title h1 {
    font-size: 47px;
    font-family: 'Rubik', sans-serif;
    opacity: 1;
    z-index: 1;
  }
  
  .signup-content {
    display: flex;
    justify-content: space-around;
    gap: 20px;
    flex-wrap: wrap;
  }
  
  .signup-card {
    background-color: #dcdce2;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.8);
    margin-bottom: 20px;
    /* text-align: center; */
    flex: 1 1 45%;
    max-width: 600px;
    border: 1px solid #4ae9e4;
    opacity: 0.9 ;
    z-index: 1;
  }
  
  .signup-card h2 {
    margin-bottom: 15px;
  }
  
  .signup-card input:not(.agreement-checkbox) {
    width: 80%;
    height: 30px;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 5px;
    border: 1px solid #4ae9e4;
    opacity: 1;
    z-index: 9999;
}

  
  .agreement {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
}

.agreement-checkbox {
  width: 23px; 
  height: 23px;
  margin-right: 10px; /* Добавляем отступ справа */
}

.agreement input {
    margin-right: 5px; /* Уменьшаем отступ справа для чекбокса */
}

.agreement a {
    color: #013B46; /* Или любой цвет, который вы предпочитаете */
    font-size: 18px;
    font-family: 'Rubik', sans-serif;
}
  
  .alert-modal {
    display: none;
    position: fixed;
    z-index: 1013B46;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0,0,0,0.4);
  }
  
  .alert-modal.show {
    display: block;
  }
  
  .alert-modal-content {
    background-color: #888;
    margin: 15% auto;
    padding: 20px;
    border: 1px solid #888;
    width: 80%;
    max-width: 500px;
    text-align: center;
    border-radius: 10px;
    box-shadow: 0 5px 15px rgba(0,0,0,0.3);
    font-family: 'Rubik', sans-serif;
    font-weight: 400;
    font-size: 18px;
  }
  
  .close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
    font-family: 'Rubik', sans-serif;

  }
  
  .close:hover,
  .close:focus {
    color: 013B46;
    text-decoration: none;
    cursor: pointer;
  }
  
  @media only screen and (max-width: 1100px) {
    .signup-card {
      width: 90%;
      margin: 10px auto;
    }
  }
  
  @media only screen and (max-width: 640px) {
    .signup-title h1 {
      font-size: 24px;
      font-family: 'Rubik', sans-serif;

    }
  
    .signup-card h2 {
      font-size: 18px;
      font-family: 'Rubik', sans-serif;

    }
  
    .agreement a {
      font-size: 15px;

    }
    .agreement-checkbox {
      width: 20px; 
      height: 20px;
      margin-right: 10px; 
    }
  }
  