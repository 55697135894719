.video-gallery-get-video {
  background-color: #dcdce2;
  border-radius: 8px;
  text-align: center;
}
.video-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.video-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 10px;
  background-color: #f9f9f9;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.video-container video {
  border-radius: 4px;
}

.btn-remove {
  margin-top: 10px;
  cursor: pointer;
  transition: color 0.3s ease;
}

.btn-remove:hover {
  color: red;
}

.delete-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}



.video-gallery-image-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
}

.video-container {
  margin: 10px;
}

.video-container video {
  width: 400px;
  height: 400px;
  border: 1px solid #003B46;
}

.video-gallery-image-window {
  background-color: #dcdce2;
  text-align: center;
  color: #003B46;
  font-size: 26px;
padding-top: 20px;
}

.video-gallery-image-window p {
  color: white;
  font-size: 26px;

}

@media only screen and (max-width: 900px) {
  .video-container video {
    max-width: 220px;
    max-height: 220px;
  }

}
@media only screen and (max-width: 600px) {

  .video-container video {
    max-width: 140px;
    max-height: 140px;
  }

  /* .video-gallery-image-window p {
    font-size: 25px;
  } */
}