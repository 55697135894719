@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Step 1 started slider section*/

.contact-form-wrapper {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 3s ease, transform 3s ease;
  /* Увеличено время анимации до 2 секунд */
}

.contact-form-wrapper.animate {
  opacity: 1;
  transform: translateY(0);
}


.home-remember-images img {
  width: 95%;
  max-height: 520px;
  display: block;
  margin: 0 auto;
  opacity: 0.8;
  /* Опционально, если нужно слегка затемнить изображение */
}

.home-remember-images::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(290deg, #373C58 0%, #dcdce2 100%);
  opacity: 0.2;
  /* Регулируйте для нужного уровня прозрачности */
  z-index: 1;
  pointer-events: none;
  /* Делает наложение некликабельным */

}

.home-image-text {
  position: absolute;
  top: 10%;
  right: 7%;
  /* padding: 5px; */
  width: 650px;
  height: 350px;
  border-radius: 5px;
  color: white;
  z-index: 2;
  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.home-image-button-container {
  margin: 0 auto;
  text-align: center;

}

.home-image-button {
  display: inline-block;
  padding: 10px 20px;
  margin-top: 10px;
  color: #4ae9e4;
  text-decoration: none;
  border-radius: 5px;
  text-align: center;
  border-style: solid;
  border-width: 1px;
  border-color: #4ae9e4;
  z-index: 9999;
  font-size: 20px;
  font-family: 'Rubik';

}

.home-image-button:hover {
  background-color: #373C58;
  color: #ffffff;
  border-color: #373C58;
}

.home-image-text p {
  font-size: 23px;
  font-family: 'Rubik';
  color: white;
}

.home-image-text h4 {
  font-size: 31px;
  font-weight: 100;
  font-family: 'Rubik';
  color: white;
}

.home-image-icon-container {
  display: flex;
  justify-content: space-around;
  margin-top: 10px;
}

.home-image-icon-item {
  text-align: center;
}

.home-image-icon-item img {
  width: 50px;
  /* Измените размер по необходимости */
  height: auto;
}

.home-image-icon-item p {
  margin-top: 5px;
  font-size: 16px;
  /* Измените размер по необходимости */
}

.home-image-icon-item h5 {
  margin: 0 auto;
}

.home-image-icon-item .icon {
  font-size: 50px;
}

.home-image-icon-desc {
  width: 200px;

}

/* Step 1 ended slider section*/


/* Step 2 started icons section*/

.home-text-container,
.home-text-container-hebrew {
  text-align: center;
  background-color: #dcdce2;
  min-width: 70%;
  margin: 0px auto;
  padding-bottom: 20px;
}

.home-text-container h4 {
  font-size: 47px;
  font-weight: 400;
  font-family: 'Rubik';
  color: #003B46;
  text-align: center;
}

.home-text-container-hebrew h4 {
  font-size: 47px;
  font-weight: 400;
  font-family: 'Rubik';
  color: #003B46;
  /* margin-left: 350px/; */
  text-align: center;
}

.home-text-container-options {
  display: flex;
  flex-direction: row;
  justify-content: center
}

.icon-container-options {
  text-align: center;
  padding: 20px 0;
  opacity: 0;
  transition: opacity 1s;
}

.icon-container-options.visible {
  opacity: 1;
}

.icon-container-options.hidden {
  opacity: 0;
}

.icon-background {
  width: 120px;
  height: 120px;
  background-color: white;
  border-radius: 80%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon-options {
  /* Любые дополнительные стили для иконки */
  font-size: 60px;
  color: #4ae9e4;

}

.icon-label-options {
  margin: 10px 0 5px 0;
  /* Отступы для заголовка */
  font-weight: bold;
  font-family: 'Rubik';
  font-size: 1.17rem;
  color: #003B46;

}

.icon-description-options {
  width: 250px;
  margin: 0 auto;
  font-size: 1rem;
  font-family: 'Rubik';
  color: #003B46;

}

.icon-container-options:nth-child(1) {
  animation-delay: 0.2s;
}

.icon-container-options:nth-child(2) {
  animation-delay: 0.4s;
}

.icon-container-options:nth-child(3) {
  animation-delay: 0.6s;
}

.icon-container-options:nth-child(4) {
  animation-delay: 0.8s;
}

.icon-container-options:nth-child(5) {
  animation-delay: 1s;
}

.icon-container-options:nth-child(6) {
  animation-delay: 1.2s;
}

.icon-container-options:nth-child(7) {
  animation-delay: 1.4s;
}

/* Step 2 ended icons section*/

/* step 3 started video section  */

.home-video-container {
  text-align: center;
  /* Центрирует видео по горизонтали */
  margin: 20px auto;
  /* Добавляет отступы сверху и снизу, выравнивает по центру */
  opacity: 0;
  transform: translateX(100%);
  transition: all 1s ease;
  background-size: cover;
  /* Обеспечивает покрытие всего контейнера фоновым изображением */
  background-position: center;
  /* Центрирует фоновое изображение */
  padding: 20px;
  border-radius: 10px;
  /* Опционально: добавляет скругление углов */
  box-shadow: 0 8px 8px rgba(0, 0, 0, 0.2);
  /* Добавляет тень */
  position: relative;

}

.home-video-container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url('../../Images/text_hebrew.jpg');
  /* Путь к вашему фоновому изображению */
  opacity: 0.2;
  /* Прозрачность изображения */
  z-index: -1;
  /* Помещает изображение на задний план */
  background-size: cover;
  /* Обеспечивает покрытие всего контейнера фоновым изображением */
  background-position: center;
  /* Центрирует фоновое изображение */
  border-radius: 10px;
  /* Опционально: добавляет скругление углов */
}

.home-video-container.visible {
  opacity: 1;
  transform: translateX(0);
}

.video-text-container {
  animation: fadeIn 1s ease forwards;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.home-video-container-main h4 {
  font-size: 47px;
  font-weight: 400;
  font-family: 'Rubik';
  color: #003B46;
  text-align: center;

}

.home-video-container iframe {
  width: 100%;
  /* Делает видео адаптивным по ширине контейнера */
  max-width: 600px;
  /* Ограничивает максимальную ширину видео */
  height: 405px;
  /* Задает высоту видео */
  border-radius: 10px;
  /* Опционально: добавляет скругление углов */
  margin: 20px;

}

.package-button {
  display: inline-block;
  padding: 10px 20px;
  color: #4ae9e4;
  text-decoration: none;
  border-radius: 5px;
  text-align: center;
  border-style: solid;
  border-width: 1px;
  border-color: #4ae9e4;
  font-size: 20px;
  font-family: 'Rubik';
  margin-top: 20px;
  animation: fadeIn 1.5s ease forwards;
}

.package-button:hover {
  background-color: #373C58;
  color: #ffffff;
  border-color: #373C58;
}

/* step 3 ended video section  */

/* step 4 started ContactForm section  */
/* ---go to the ContactForm styles */
/* step 4 started ContactForm ended  */

/* step 5 started questions section  */


.home-question-container {
  text-align: center;
  margin: 20px auto;
  position: relative;
  max-height: 400px;
  /* display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; */
  transform: translateX(50px);
  opacity: 0;
  transition: opacity 1s ease, transform 1s ease;
}

.home-question-container.animate {
  margin: 0 auto;
  opacity: 1;
  transform: translateY(0);
}

.home-question-container h4 {
  font-size: 47px;
  font-weight: 400;
  font-family: 'Rubik';
  color: #003B46;
  text-align: center;
  z-index: 1;
  /* Помещает текст на передний план */
}

.home-question-content {
  text-align: center;
  margin: 20px auto;
  padding: 20px;
  border-radius: 10px;
  position: relative;
  width: 100%;
  max-height: 400px;
  /* Установите высоту, чтобы контейнер был виден */
  /* display: flex;
  align-items: center;
  justify-content: center; */
  z-index: 1;
  /* Помещает текст на передний план */
  box-shadow: 0 8px 8px rgba(0, 0, 0, 0.2);
  text-align: center;
}

.home-question-content::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url('../../Images/icon_question.jpg');
  background-size: cover;
  background-position: center;
  opacity: 0.2;
  border-radius: 10px;
  z-index: -1;

}

.home-question-container h3 {
  font-size: 47px;
  font-weight: 400;
  font-family: 'Rubik';
  color: #003B46;
  text-align: center;
}

/* step 5 ended questions section  */

@media screen and (max-width: 900px) {

  .home-text-container-options {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  }
  .slick-prev,
  .slick-next {
    display: none !important;
  }
  
  .slick-prev:before,
  .slick-next:before {
    display: none !important;
  }
}

@media screen and (max-width: 600px) {
  /* step 1 started slider section */

  .home-remember-images img {
    width: 95%;
    max-height: 220px;
    display: block;
    margin: 0 auto;
    opacity: 0.8;
    /* Опционально, если нужно слегка затемнить изображение */
  }

  .home-image-text {
    width: 88%;
    height: 85%;
    
    
  }

  .home-image-button {
    padding: 2px 6px;
    font-size: 10px;
  }

  .home-image-text p {
    font-size: 10px ;
    text-align: center;
    margin: 0 auto;
    font-family: 'Rubik';
  }

  .home-image-description{
    font-size: 14px !important;
  }

  .home-image-text h4 {
    font-size: 20px;
    font-family: 'Rubik';

  }

  .home-image-icon-item p {
    /* margin-top: 0; */
    width: 70px;
    font-size: 8px;
  }

  .home-image-icon-item .icon {
    font-size: 30px;
  }

  .home-image-icon-desc {
    width: 40px;

  }

  /* step 1 ended slider  section*/

  /* step 2 started icons  section*/

  .home-text-container-hebrew h4 {
    font-size: 18px;
  }

  .home-text-container-options {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  }

  .icon-container-options {
    display: block;
    flex-direction: row-reverse;
    text-align: center;

  }
  .icon-background {
    width: 60px;
    height: 60px;
    background-color: white;
    border-radius: 80%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .icon-options {
    font-size: 40px;
  }

  .icon-label-options,
  .icon-description-options {
    font-size: 1rem;
  }

  .icon-description-options {
    font-size: 0.8rem;
    width: 185px;
  }

  /* step 2 ended icons section */

  /* step 3 started video section  */
  .home-video-container-main h4 {
    font-size: 20px;

  }

  .home-text-container h4 {
    font-size: 20px;
    text-align: center;

  }

  .home-video-container iframe {
    width: 80%;
    /* Делает видео адаптивным по ширине контейнера */
    max-width: 300px;
    /* Ограничивает максимальную ширину видео */
    height: 205px;
    /* Задает высоту видео */
  }

  .package-button {
    padding: 5px 10px;
    font-size: 10px;
    margin-top: 10px;
  }

  /* step 3 ended video section  */

  /* step 4 started ContactForm section  */
  /* ---go to the ContactForm styles */
  /* step 4 started ContactForm ended  */

  /* step 5 started questions section  */

  .home-question-container h3 {
    font-size: 20px;
  }

  .home-question-content {
    display: block;
    padding: 0px;
    margin-top: 10px;
    padding-top: 70px;
  }

  /* step 5 ended questions section  */

}