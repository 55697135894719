.photo-uploader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
}

.custom-file-input {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  /* background-color: #4ae9e4; */
  border: 2px dashed #003B46;
  padding: 10px;
  border-radius: 10px;
  transition: background-color 0.3s;
}

.add-photo-btn {
  font-size: 3rem;
  color: #003B46;
}

.new-photo-container {
  margin-top: 20px;
  text-align: center;
}

.photo-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 15px;
}

.photo-item {
  position: relative;
  width: 150px;
  height: 150px;
  overflow: hidden;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.uploaded-photo {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.btn-remove {
  position: absolute;
  top: 5px;
  right: 5px;
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 50%;
  padding: 5px;
}

.btn-remove:hover {
  background-color: rgba(255, 0, 0, 0.8);
  color: #fff;
}

.upload-button-container {
  margin-top: 20px;
}

.upload-button {
  background-color: #003B46; 
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s;
}

.upload-button:hover {
  background-color: #00bddf;
}
