.error-page-container {
    background-color: #dcdce2;
    padding: 40px;
}

.error-page-details {
    text-align: center;
    background-color: #fff;
    height: 15vw;
    border-radius: 8px;
    padding: 40px;

}

@media only screen and (max-width: 900px) {
    .error-page-container {
        background-color: #dcdce2;
        padding: 40px;
        height: 60vw;
        margin-top: 100px;
    }

    .error-page-details {
        text-align: center;
        background-color: #fff;
        height: 25vw;
        border-radius: 8px;
        padding: 40px;

    }
}


@media only screen and (max-width: 600px) {

    .error-page-container {
        background-color: #dcdce2;
        padding: 40px;
    }

    .error-page-details {
        text-align: center;
        background-color: #fff;
        height: 35vw;
        border-radius: 8px;
        padding: 40px;

    }

    .error-page-details button {
        margin-top: 40px;
    }
}