.pesone-history-container {
  background-color: #dcdce2;

}

.pesone-history-details {
  display: flex;
  padding: 10px;
  padding-top: 500px;
  text-align: center;
  z-index: 0;
  justify-content: center;
}

.phone-languages {
  display: none;
}

.persone-add-background-foto {
  width: 100%;
  max-height: 600px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  z-index: 0;
}

.story-text-phone {
  display: none;
}


/* .pesone-history-memories {
  position: absolute;
  top: 800px; 
  width: 1000px;
  background-color: white;
  text-align: center;
} */

.pesone-history-container-photo {
  /* Убираем рамку изначально, так как она будет задаваться внутри класса .photo */
  /* height: 85%; */
  /* position: relative; */
}

.pesone-history-btns {
  /* text-align: center; */
}

.no-photo-border {
  /* Убираем рамку изначально, так как она будет задаваться внутри класса .photo */
  /* border: none !important; */
}

.photo {
  border: 15px solid white;
  max-width: 400px;
  height: 300px;
  width: auto;
  height: auto;
  object-fit: cover;
  box-shadow: 0 0 20px rgba(194, 194, 194, 0.5);
  /* clip-path: inset(0% 0 10% 0); */
  position: relative;
  margin-top: 5px;
  position: relative;


}

.no-photo {
  clip-path: initial;
}

/* Добавляем рамку */
.photo:before {
  content: '';
  position: absolute;

}

.about-persone {
  padding: 42px;
  box-shadow: 0 0 20px rgba(194, 194, 194, 0.5);
  font-size: 35px;
  color: #003B45;
  text-align: center;
  border-radius: 5px;
  font-weight: bold;
  position: relative;
  margin-top: 7px;
  padding-left: 50px;
  z-index: 9999;
  background-color: white;
  min-height: 200px;
  min-width: 400px;
}

.about-persone-text {
  box-shadow: 0 0 20px rgba(194, 194, 194, 0.5);
  /* background-color: #4ae9e4; */
  padding: 20px;
  margin-bottom: 20px;
  margin-left: 3px;
  padding-left: 50px;

}

.about-persone-story {
  font-size: 23px;
  font-family: 'Rubik';
  font-weight: 400;
  position: relative;
}

.about-persone-text p {
  /* color: white; */
  font-family: 'Rubik';
  font-size: 16px;
  font-weight: 400;
  /* text-align: justify; */

}

.story-container {
  padding: 10px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 10px 0;
  text-align: center;
  line-height: 1.5;
  overflow: hidden;
  /* Добавлено для управления переполнением */

}

.story-text-comp {
  margin: 20px auto;
  width: 90%;
  font-family: 'Rubik';
  font-size: 18px;
  color: #333;
  word-break: break-word;
  line-height: 1.6;
  background-color: #f0f0f0;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 10px;
  /* добавляет отступ между блоками */
  transition: opacity 1s ease-in-out;
}

.pesone-history-stories {
  display: flex;
  justify-content: center;
  flex-direction: row;
  justify-content: space-around;

}

.fade-in {
  opacity: 1;
}

.pesone-history-story {
  width: 350px;
  text-align: center;

}

.pesone-history-story h2 {
  color: #013B46;
  font-style: normal;
  font-family: 'Rubik';
  font-size: 24px;
  font-weight: bold;
}

.pesone-history-story p {
  color: #676767;
  font-style: normal;
  font-family: 'Rubik';
  font-size: 16px;
}

.container-detail {
  display: flex;
  /* justify-content: space-evenly; */
  /* padding: 0 5px 0 5px; */
}

.detail-item {
  /* display: flex; */
  /* background-color: #003B45; */
  /* color: white; */
  font-size: 20px;
  padding: 10px;


}

.detail-item p {
  font-size: 20px;
  font-weight: 400;
  font-family: 'Rubik';
  color: #003B46;

}

.detail-item-size {
  /* width: 33.33%; */
  display: flex;
  flex-direction: row;
}


.button-overlay {
  position: absolute;
  top: 40%;
  left: 13%;
  transform: translate(50%, -50%);
  height: 80px;
  width: 400px;
}

.button-overlay-hebrew {
  position: absolute;
  top: 40%;
  left: 14%;
  transform: translateX(-50%);
  height: 80px;
  width: 400px;
}


/* .stick {
  height: 1px;
  width: 50px;
  background-color: #013B46;
  margin: 0 30px;
  margin-right: 20px;
  margin-top: 15px;
}

.slick-dots {
  width: 5px;
  height: 5px;
}

.slick-dots li {
  border: 1px solid #003B45;
  border-radius: 10px;
  max-width: 12px;
  max-height: 12px;
}

.slick-dots li button {
  border-radius: 50%;
  max-width: 12px;
  max-height: 12px;
}

.slick-dots li.slick-active button {
  background-color: #003B45;

} */

.pesone-history-memories {
  background-color: white;
  text-align: center;
}

.pesone-history-memories-carousel {
  max-width: 350px;
  border: 1px solid #4ae9e4;
  margin: 0 10px;
}

.pesone-history-memories-border {
  border: 0.5px solid #4ae9e4;
  min-height: 320px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 10px;
}

.pesone-history-memories-border img {
  margin: 30px auto;
  width: 32px;
  height: 32px;
}

.pesone-history-memories-border .memory-text {
  width: 200px;
  word-wrap: break-word;
  margin: 0 auto;
}

.pesone-history-memories h2 {
  text-align: center;
  color: #003B45;
  font-size: 47px;
  font-weight: bold;
}

.pesone-history-memories h3,
.pesone-history-memories p {
  margin-left: 60px;
  margin: 20px;
  font-family: 'Rubik', sans-serif;
  font-weight: 400;
}

.pesone-history-memories_hebrew h3,
.pesone-history-memories_hebrew p {
  text-align: right;
  margin-right: 20px;
  font-family: 'Rubik', sans-serif;
  font-weight: 400;
}

.pesone-history-btns .package-button {
  margin-top: 20px;
}


/* 

.pesone-history-memories h2 {
  margin-bottom: 20px
}







.slide-content {
  margin: 0 auto;
  max-width: 80%;
}



.container-detail {
  margin: 0 auto;
  max-width: 900px;
}



.pesone-history-location-p {
  font-size: 16px;
  font-family: 'Rubik';
  font-style: light;
  text-align: center;
  padding-bottom: 20px;

}

*/


.custom-modal {
  border: 1px solid #4ae9e4;
  background-color: #dcdce2;
}

.custom-modal h2 {
  text-align: center;
  color: #013B46;
  font-family: 'Rubik', sans-serif;
  font-weight: 400;
  font-size: 30px;

}

.custom-modal.show {
  opacity: 1;
  transform: scale(1);
}

.custom-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}


.pesone-history-location {
  position: relative;
  text-align: center;
}

.pesone-history-location-btn {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
}

.local-btn {
  width: 294px;
  height: 60px;
  border: solid 1px #4ae9e4;
}

.pesone-history-location img {
  width: 846px;
}

.pesone-history-freeText {
  margin-top: 40px;
}

.person-photo-video-container {
  margin: 20px
}

.about-persone-phone {
  display: none;
}

.modal-slider {
  position: absolute;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #dcdce2;
  padding: 20px;
  max-height: 100vh;
  overflow-y: auto;
  border-radius: 8px;
  width: 600px;
  text-align: center;
  overlay: 0;
}

.modal-slider p {
  font-size: 14px
}

@media only screen and (max-width: 1100px) {

  .pesone-history-details {
    flex-wrap: wrap;
  }

  .about-persone {
    width: 740px;
  }

  .story-text-phone {
    display: none;
  }

}

@media only screen and (max-width: 900px) {

  .persone-add-background-foto {
    max-height: 300px;
  }

  .pesone-history-details {
    padding-top: 250px;

  }

  .photo {
    border: 15px solid white;
    max-width: 460px;
    height: 430px;
    width: auto;
    height: auto;
    object-fit: cover;
    box-shadow: 0 0 20px rgba(194, 194, 194, 0.5);
    /* clip-path: inset(0% 0 10% 0); */
    position: relative;
    margin-top: 5px;

  }

  .no-photo {
    max-height: 278px;
    max-width: 293px;
    margin-left: 15px
  }

  .about-persone {
    padding: 42px;
    box-shadow: 0 0 20px rgba(194, 194, 194, 0.5);
    font-size: 35px;
    color: #003B45;
    text-align: center;
    border-radius: 5px;
    font-weight: bold;
    position: relative;
    margin-top: 7px;
    padding-left: 50px;
    z-index: 9999;
    background-color: white;
    min-height: 200px;
    width: 400px;
  }

  .button-overlay {
    height: 60px;
    width: 240px;
    font-size: 16px;
  }

  .button-overlay-hebrew {
    height: 60px;
    width: 240px;
  }

  .story-text-phone {
    display: none;
  }

  .custom-modal {
    width: 400px;
    height: 100px;
    background-color: red;
  }


  /* .pesone-history-details {
    flex-wrap: wrap;
  }

  .nav {
    display: flex;
  }

  .about-persone {
    display: none;
  }

  .photo {
    width: 293px;
    max-height: 279px;

  }

  .pesone-history-container-photo {
    border: 15px solid white;
    max-height: 279px;
    width: 293px;
    position: absolute;
    top: 280px;
    left: 50%;
    transform: translate(-50%, -50%);

  }

  .no-photo {
    max-height: 278px;
    max-width: 293px;
    margin-left: 15px
  }

  
  .about-persone-phone {
    display: flex;
    font-size: 40px;
    color: white;
    text-align: center;
    margin: auto;
    justify-content: center;
    padding-top: 150px;

  }

  .about-persone-text {
    margin-top: 100px;
    width: 356px;
    text-align: center;
  }

  .about-persone-text p {
    text-align: inherit;

  }

  .detail-item {
    flex-wrap: wrap;
    padding: 10px;
  }

  .detail-item-size {
    width: 100%;
  }

  .pesone-history-memories h2 {
    font-size: 40px;
  }

  .pesone-history-memories {
    width: 600px;

  }

  .modal-slider {
    width: 400px;

  }

  .pesone-history-location img {
    width: 530px;
    height: 345px;
  }

  .pesone-history-location-btn {
    right: 160px;
  } */
}

@media only screen and (max-width: 600px) {

  .persone-add-background-foto {
    max-height: 300px;
  }

  .pesone-history-details {
    padding-top: 250px;

  }

  .photo {
    border: 15px solid white;
    max-width: 260px;
    height: 230px;
    width: auto;
    height: auto;
    object-fit: cover;
    box-shadow: 0 0 20px rgba(194, 194, 194, 0.5);
    /* clip-path: inset(0% 0 10% 0); */
    position: relative;
    margin-top: 5px;

  }

  .about-persone {
    padding: 0px;
    box-shadow: 0 0 20px rgba(194, 194, 194, 0.5);
    font-size: 20px;
    border-radius: 5px;
    font-weight: bold;
    z-index: 9999;
    background-color: white;
    font-family: "Rubik";
  }

  .about-persone p {
    text-align: center;
    font-size: 20px;
    margin: 5px;
    font-family: "Rubik";
  }



  .story-text-comp {
    display: none;
  }

  .story-text-phone {
    display: flex;
    font-size: 18px;
    color: #333;
    word-break: break-word;
    font-family: "Rubik";
  }

  .story-toggle-button {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-top: 10px;

    margin: 0 auto;
  }

  .pesone-history-location-btn {
    position: absolute;
    bottom: 130px;
    right: 80px;
    z-index: 0;

  }

  .local-btn {
    min-width: 250px;
    height: 60px;
  }

  .pesone-history-location img {
    width: 330px;
    height: 345px;
  }


  .pesone-history-memories h2 {
    font-size: 24px;
  }

  .slide-content {
    margin: 0 auto;
    max-width: 100%;
  }



  .about-persone-text {
    text-align: center;
    padding: 20px;
    width: 300px;
  }


  .phone-languages {
    display: flex;
    justify-content: center;
    padding-top: 10px;
  }

  .persone-add-background-fото {
    height: 250px;
  }

  .photo {
    min-width: 194px;
    height: 185px;
    z-index: 1000;
    max-width: 194px;
  }

  .no-photo-border {
    border: none !important;
  }

  .no-photo {
    min-width: 194px;
    height: 185px;
    margin-left: 0%;
  }

  .button-overlay {
    height: 50px;
    width: 150px;
    font-size: 14px;
    font-weight: bold;
  }

  .button-overlay-hebrew {
    top: 40%;
    left: 8%;
    height: 50px;
    width: 160px;
    font-size: 14px;
    font-weight: bold;

  }

  .pesone-history-container-photo {
    border: 15px solid white;
    height: 168px;
    width: 194px;
    position: absolute;
    top: 280px;
    left: 50%;
    transform: translate(-50%, -50%);
  }


  .pesone-history-stories {
    display: block;
    padding-bottom: 50px;
  }

  .pesone-history-story {
    width: 90%;
    text-align: center;
    margin: auto;
  }

  .detail-item {
    display: block;
    padding: 5px;
    font-size: 16px;
  }

  .detail-item-size {
    width: 100%;
  }

  .modal-slider {
    width: 250px;
  }

  .custom-modal {
    width: 350px;
    margin: 80px auto;
    background-color: transparent;
    padding: 20px;
    border-radius: 8px;
    overflow-y: auto;
    max-height: 80vh;
    z-index: 3;
  }

  .custom-modal h2 {
    font-size: 20px;
  }

}