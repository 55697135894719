.photo-gallery-container {
  background-color: #dcdce2;
}

.photo-gallery-get-photo {
  border-radius: 8px;
  background-color: #dcdce2;
  margin: auto;
}

.photo-gallery-get-photo h2 {
  text-align: center;
}

.photo-gallery-image-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

}

.photo-gallery-image-container img {
  height: 300px;
  object-fit: cover;
  margin: 5px;
  border: 15px solid white
}

.photo-gallery-image-window p {
  color: white;
  font-size: 26px;
}

.PhotoGalleryModal img {
  width: auto;
  height: auto;
}

.PhotoGalleryModalContent {
  position: relative;
}

.PhotoGalleryModal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}

.PhotoGalleryModal img {
  width: 500px;
  height: 500px;
  object-fit: contain;
}

.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.photo-gallery-image-window {
  display: flex;
  flex-direction: column;
  color: white;
  text-align: center;
}

.btn-remove {
  font-size: 30px;
  cursor: pointer;
  margin-top: 10px;
  text-align: center;
}

.photo-gallery-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
}

.photo-gallery-modal-close-button {
  position: absolute;
  top: 20px;
  right: 10px;
  cursor: pointer;
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 50%;
  padding: 5px;
}

.photo-gallery-modal-delete-button {
  position: absolute;
  bottom: 20px;
  
  cursor: pointer;
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 50%;
  padding: 5px;
}



@media only screen and (max-width: 700px) {

  .photo-gallery-get-photo {
    width: 100%;
  }

  .photo-gallery-get-photo h2 {
    padding: 20px;
  }

  .photo-gallery-image-container img {
    padding: 2px;
    width: 136px;
    height: 136px;
  }

  .PhotoGalleryModal img {
    width: 350px;
    height: auto;
    object-fit: cover;

  }

  .photo-gallery-image-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    margin: 1px;

  }




}