.forgot-password-container {
  position: relative;
  padding: 20px;
  background-color: rgba(0, 0, 0, 0.5); 
  text-align: center;
  height: 80vh;
  overflow: hidden;
}

.forgot-password-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('../../Images/many_people.jpg'); /* Path to your background image */
  opacity: 0.5; /* Image transparency */
  z-index: 1;
}

.forgot-password-details {
  position: relative;
  z-index: 2; /* Ensure the content stays above the background image */
  opacity: 1; /* Full opacity for the content */
}

.forgot-password-details p {
  color: #fff;
  font-size: 20px;
  font-family: 'Rubik', sans-serif;
  font-weight: 400;
  text-align: center;
  opacity: 1;
  z-index: 9999;
}

.forgot-password-contant {
  opacity: 1;
  z-index: 2; /* Ensure the content stays above the background image */
}

.forgot-password-contant h4{
  color: #fff;
  font-size: 47px;
  font-family: 'Rubik', sans-serif;
  font-weight: 800;
  text-align: center;
  opacity: 1;
  z-index: 9999;
}

.forgot-password-contant button{
margin-top: 40px;
}