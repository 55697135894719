h2 {
  /* margin: 0; */
  /* color: #003B46; */
  /* text-align: center; */
  /* margin-top: 20px; */
  z-index: 0,

}

p {
  /* margin-bottom: 20px; */
  text-align: center;
}

.cancel-button {
  padding: 15px 30px;
  background-color: #ff0000;
  border: none;
  color: #fff;
  cursor: pointer;
  border-radius: 5px;
  /* transition: background-color 0.5s; */
  margin-left: 20px;
  z-index: 3,

}

/* .cancel-button:hover {
  background-color: #ff5656;
}
 */
.confirm-button {
  padding: 15px 40px;
  background-color: #66A5AD;
  border: none;
  color: #fff;
  cursor: pointer;
  border-radius: 5px;
  /* transition: background-color 0.3s; */
  margin-left: 20px;
  z-index: 3,

}

/* .confirm-button:hover {
  background-color:  #003B46;
} */

@media only screen and (max-width: 600px) {
  .cancel-button {
    text-align: center;
    margin-right: 30px;
    margin-left: 15px;
  }
}