
.country-selector-container,
.country-selector-container-hebrew {
    width: 84%;
    margin: 0 auto;
    /* padding-left: 24px; */
}

.country-selector-input {
    width: 100%;
    /* padding: 10px; */
    margin-bottom: 10px;
    border-radius: 5px;
    font-family: 'Rubik', sans-serif;
}

@media (max-width: 768px) {
    .country-selector-container,
    .country-selector-container-hebrew {
        width: 100%;
    }
    
    .country-selector-input {
        width: 84%;
        margin: 0 auto;
    }
}

@media (max-width: 480px) {
    .country-selector-container,
    .country-selector-container-hebrew {
        width: 100%;
    }
    
    .country-selector-input {
        width: 87%;
        margin: 0 auto;

    }
}
