.input-container,
.contact-input-group {
  margin: 20px;
}

.styled-input,
.textarea-input {
  /* width: 105%; */
  width: 500px;
  padding: 15px 20px;
  font-size: 16px;
  border: 2px solid #4ae9e4;
  border-radius: 10px;
  outline: none;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 0 auto;

}

.styled-input-hebrew {
  /* width: 105%; */
  width: 300px;
  padding: 15px 20px;
  font-size: 16px;
  border: 2px solid #4ae9e4;
  border-radius: 10px;
  outline: none;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 0 auto;
  margin-right: 20px;
}

.textarea-input-hebrew {
  /* width: 105%; */
  width: 500px;
  padding: 15px 20px;
  font-size: 16px;
  border: 2px solid #4ae9e4;
  border-radius: 10px;
  outline: none;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.styled-input::placeholder,
.textarea-input::placeholder {
  color: #aaa;
}

.styled-input:focus,
.textarea-input:focus {
  border-color: #373C58;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
}

.styled-input.mobile,
.textarea-input.mobile {
  max-width: 260px;
}

.styled-input.desktop,
.textarea-input.desktop {
  max-width: 560px;
}

.textarea-input {
  height: 100px;
  resize: vertical;
}

.contact-us_hebrew h2,
.contact-us h2 {
  margin-bottom: 20px;
  font-size: 24px;
  color: #333;
  font-family: 'Rubik', sans-serif;
}

@media only screen and (max-width: 768px) {

  .styled-input,
  .textarea-input {
    width: 500px;
  }

  .textarea-input-hebrew {
    /* width: 105%; */
    width: 500px;
    margin-right: 0px;

  }

}

@media only screen and (max-width: 600px) {

  .styled-input,
  .textarea-input {
    width: 220px;
  }

  .textarea-input-hebrew {
    width: 220px;
  }

}