.banner-modal {
    position: fixed;
    top: 63%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #003B46;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    max-width: 500px;
    text-align: center;
    border: none;
    outline: 0;
}

.banner-modal-img img {
    width: 200px;
    height: 200px;
}

.banner-modal h2 {
    font-size: 24px;
    margin-bottom: 10px;
    color: white;

}

.banner-modal p {
    font-size: 16px;
    margin-bottom: 20px;
    color: white;

}

.banner-modal button {
    background-color: #07575b;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    font-family: 'Rubik', sans-serif;
    margin-top: 10px;
    min-height: 60px;
    outline: 0;

}

.banner-modal button:hover {
    /* background-color: #07575B; */
    font-size: 18px;
    font-weight: bold;
    font-family: 'Rubik', sans-serif;

}

.close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 27px;
    color: white;
    cursor: pointer;
}

.close-btn:hover {
    color: #000;
}