.leaflet-control-attribution {

  display: none;
}

.settings-persone-container {
  /* background-color: #003B46; */
  background-color: white;

}


.settings-persone {
  background-color: #dcdce2;
  margin: 20px;
  padding-bottom: 20px;
  border-radius: 9px;
  margin: 0 auto;
  /* max-width: 1000px; */
  /* width: 1000px; */

}

/* .settings-persone h2 {
  padding: 20px;
  text-align: center;

} */

.settings-persone-img {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}


/* .regular-button-container {
  position: absolute;
  top: 55%; 
  left: 50%; 
  transform: translate(-50%, -50%); 
} */

/* 
.settings-persone-img img {
  width: 100%;
  max-height: auto;
  border-radius: 8px;
  margin-bottom: 10px;
  width: 200px;
  height: 200px;
  object-fit: cover;

} */

.settings-persone-btn {
  margin-top: 20px;
  text-align: center;
}

/* .settings-persone-btn-remove-and-change {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
} */

.settings-person {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.settings-title {
  color: black;
  font-size: 24px;
  margin-bottom: 20px;
}

.image-container {
  margin-top: 40px;
  width: 100%;
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #ddd;
  border-radius: 10px;
  overflow: hidden;
}

.background-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.photo-wrapper {
  margin-top: 40px;
  text-align: center;
}

.image-preview {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}

.photo-image {
  width: 70%;
  height: auto;
  border: 2px solid #ddd;
  border-radius: 10px;
}

.button-group {
  display: flex;
  justify-content: center;
  gap: 10px;
}

.button-primary,
.button-secondary,
.button-danger {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.button-primary {
  background-color: #6c757d;
  color: white;
}

.button-secondary {
  background-color: #6c757d;
  color: white;
}

.button-danger {
  background-color: #dc3545;
  color: white;
}

.add-photo {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  border: 2px dashed #ddd;
  border-radius: 10px;
  padding: 20px;
  margin-top: 20px;
}

/* .no-image {
  width: 100px;
  height: 100px;
  margin-top: 10px;
} */

.regular-button-container {
  margin-top: 20px;
}


.settings-persone-location {
  text-align: center;
  font-family: 'Merriweather';
  font-style: light;
  font-weight: 600;
}

.settings-persone-location label {
  margin: 20px;
  font-size: 16px;
  font-weight: bold;
  font-family: 'Merriweather';
  font-style: light;
}

.settings-persone-location p {
  font-size: 25px;
  text-align: center;
  font-weight: bold;
  font-family: 'Merriweather';
  font-style: light;
}

.settings-persone-location input[type="checkbox"] {
  transform: scale(1.6);
  /* Увеличиваем размер чекбокса в 2 раза */
  margin-right: 10px;
  /* Добавляем небольшой отступ между чекбоксом и текстом */
}

.input-with-hidden-placeholder::placeholder {
  color: transparent;
}

.settings-persone-map {
  height: 50vh;
  width: 80vh;
  border: solid 1px #4ae9e4;

}

/* TABS */

.tab-names {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.tabs-container {
  display: flex;
  /* flex-direction: column; */
  padding: 20px;
  background-color: #dcdce2;
}

.tabs {
  display: flex;
  flex-direction: column;
}

.tab {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: #fff;
  border: none;
  padding: 10px 15px;
  margin: 7px;
  cursor: pointer;
  font-size: 16px;
  font-family: 'Lora', sans-serif;
  font-weight: 800;
  white-space: nowrap;
  width: 200px;
}

.tab div.tab-names {
  display: flex;
  align-items: center;
}

.tab div.tab-names svg {
  width: 28px;
  /* Размер иконок */
  height: 28px;
  /* Размер иконок */
  margin-right: 5px;
  /* Отступ между иконкой и текстом */
}


.tab p {
  margin-left: 5px;
}

.tab:hover {
  background-color: #66a5aD;
}

.tab.active {
  background-color: #4ae9e4;
  color: #003B46;
}

.tab-content {
  padding: 20px;
  background-color: #fff;
  margin: 20px;
  padding-bottom: 20px;
  border-radius: 9px;
  margin: 0 auto;
}

.tab-content h3 {
  padding: 20px;
  text-align: center;

}

.tab-content-img {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 400px;
  height: 330px;
}

.tab-content-img img {
  /* width: 100%;
  max-height: auto; */
  max-width: 400px;
  height: 330px;
  border-radius: 8px;
  margin-bottom: 10px;
}

.tab-content-btn {
  margin-top: 20px;
  text-align: center;
}

.settings-persone-title {
  width: 350px;
}

.settings-persone-memory-container {
  width: 500px;
  text-align: center;

}

.settings-persone-text-area-size {
  min-height: 400px;
  width: 600px;
}

.settings-persone-input-label {
  margin-left: 12px;
}

.settings-persone-input-label-hebrew {
  margin-right: 12px;

}

@media screen and (max-width: 1350px) {
  .settings-persone-map {
    height: 50vh;
    width: 40vh;
  }

  .settings-persone-text-area-size {
    min-height: 400px;

  }
}

@media screen and (max-width: 800px) {
  .settings-persone-map {
    height: 30vh;
    width: 30vh;
  }



}


@media screen and (max-width: 600px) {

  .settings-persone-memory-container {
    width: 300px;
    text-align: center;

  }

  .tabs-container {
    display: flex;
    flex-direction: column;
    background-color: #fff;

  }

  .tabs {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-bottom: 20px;

  }

  .tab-names {
    display: flex;
    flex-direction: row;
    margin: 0 auto;
    font-size: 35px;
  }

  .tabs p {
    display: none;
    margin: 20px;
  }

  .tab {
    background-color: #dcdce2;
    border: none;
    padding: 10px 15px;
    margin: 5px;
    cursor: pointer;
    font-size: 12px;
    font-family: 'Lora', sans-serif;
    font-weight: 600;
    white-space: nowrap;
  }

  .tab-content {
    padding: 5px;
    /* background-color: #dcdce2;   */
    margin: 0px;
    padding-bottom: 20px;
    border-radius: 9px;
    margin: 0 auto;
    overflow-x: auto;
  }

  .tab:hover {
    background-color: #4ae9e4;
    font-size: 17px;
  }

  .tab.active {
    background-color: #66a5aD;
    color: #003B46;
  }


  .tab-content h3 {
    padding: 20px;
    text-align: center;
  }

  .tab-content-img {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .tab-content-img img {
    width: 100%;
    max-height: auto;
    border-radius: 8px;
    margin-bottom: 10px;
  }

  .tab-content-btn {
    margin-top: 20px;
    text-align: center;
  }

  .settings-persone-btnn-remove-and-change {
    display: block;
    width: 100%;
    margin: auto;
    text-align: center;
  }

  .settings-persone-title {
    max-width: 300px;
    margin: auto;
  }

  /* .settings-persone-title h2 {
    font-size: 18px;
  }

  .settings-persone {
    min-width: 300px;


  } */

  .settings-persone-img img {
    width: 200px;
    height: 200px;
  }

  .settings-persone-text-area-size {
    min-height: 400px;
    width: 250px;
  }


  .settings-persone h2 {
    padding: 0px;

  }

  .settings-persone-map {
    height: 50vh;
    width: 40vh;
  }
}