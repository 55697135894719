.faq-section {
  max-width: 800px;
  margin: 20px auto;
}

.faq-item {
  background-color: #f9f9f9;
  margin-bottom: 10px;
  border-radius: 5px;
  padding: 15px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.faq-item.open {
  background-color: #e9e9e9;
}

.faq-question h4 {
  margin: 0;
  font-size: 20px;
  color: #003B46;
  font-family: 'Rubik', sans-serif;
}

.faq-answer {
  margin-top: 10px;
  display: none;
  font-size: 16px;
  color: #333;
  font-family: 'Rubik', sans-serif;
}

.faq-item.open .faq-answer {
  display: block;
}

.faq-slide {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.faq-item-dot-item {
  width: 20px;
  height: 20px;
  /* border: 1px #4ae9e4 solid; */
  background-color: white;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 900px) {
  .faq-item {
    border-bottom: 1px solid #ccc;
    padding: 10px;
    width: 600px;
    margin: 10px auto;
    /* margin-right: 50px; */
  }
}


@media screen and (max-width: 600px) {
  .faq-section {
    margin: 0 auto;
    padding-bottom: 20px;
  }

  .faq-item {
    border-bottom: 1px solid #ccc;
    padding: 10px;
    width: 300px;
    margin: 3px auto;
  }

  .faq-question h4 {
    font-size: 14px;
    font-family: 'Rubik', sans-serif;
  }

  .faq-answer {
    font-size: 12px;
    color: #333;
    font-family: 'Rubik', sans-serif;
  }

  .slick-dots {
    position: absolute;
    bottom: -25px;
    display: block;
    width: 100%;
    padding: 0;
    margin: 0;
    list-style: none;
    text-align: center;
    transition: opacity 0.3s ease;
  }

  .dots-hidden .slick-dots {
    opacity: 0;
    /* Скрытие точек */
  }

  .faq-item-dot-item {
    width: 10px;
    height: 10px;
    border: 1px white solid;
    background-color: white;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}