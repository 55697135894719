.spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    background-color: #dcdce2;
    z-index: 9999;
    overflow: hidden;
}

.spinner-image {
    width: 300px;
    height: 300px;
    position: absolute;
    animation: slide-in-out 5s infinite;
}

@keyframes slide-in-out {
    0% {
        transform: translateX(-100vw) rotate(0deg);
        opacity: 0;
    }
    25% {
        transform: translateX(0) rotate(360deg);
        opacity: 1;
    }
    75% {
        transform: translateX(0) rotate(360deg);
        opacity: 1;
    }
    100% {
        transform: translateX(100vw) rotate(720deg);
        opacity: 0;
    }
}
