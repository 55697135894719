
@keyframes slideInFromTop {
  0% {
    opacity: 0;
    transform: translateY(-50px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.packages-container {
  position: relative;
  padding: 20px;
  background-color: #dcdce2;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.packages-container h2{
  font-size: 47px;
  font-weight: 400;
  font-family: 'Rubik';
  color: #003B46;
  width: 95%;
  text-align: center;
}

.packages-container-hebrew h2{
  font-size: 47px;
  font-weight: 400;
  font-family: 'Rubik';
  color: #003B46;
  width: 95%;
  text-align: center; 
}


.package-direction {
  display: flex;
  flex-direction: flex;
  justify-content: center;
}
.package-option {
  border: solid 1px white;
  margin: 20px;
  width: 350px;
  min-height: 400px;
  border-radius: 20px;
  transition: all 0.3s ease;
  position: relative;
  background-color: white;
  box-shadow: 0 8px 8px rgba(0, 0, 0, 0.2); /* Добавляет тень */
  opacity: 0;
  transform: translateY(-50px);
  animation: slideInFromTop 0.5s forwards;
}

.package-option.animation-delay-1 {
  animation-delay: 0.2s;
}

.package-option.animation-delay-2 {
  animation-delay: 0.4s;
}

.package-option.animation-delay-3 {
  animation-delay: 0.6s;
}

.package-option.premium::before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  width: 0;
  height: 0;
  border-top: 75px solid #4ae9e4;
  border-left: 75px solid transparent;
  border-radius: 0 20px 0 0;
}

.package-option.premium::after {
  content: 'Best Seller';
  position: absolute;
  top: 47px;
  right: 5px;
  color: #000000; /* Черный цвет текста */
  font-size: 14px; /* Размер текста */
  font-weight: bold; /* Жирный шрифт */
  transform: rotate(45deg);
  transform-origin: top right;
}



.package-option p{
  font-size: 35px;
  font-weight: 800;
  font-family: 'Rubik';
  box-shadow: 0 8px 8px rgba(0, 0, 0, 0.2); /* Добавляет тень */

}

.icon-size {
  font-size: 15px;
  color: #c4dfe6;
  margin-top: 5px;
  margin-left: 5px;
  margin-right: 5px;
}

.package-option ul {
  list-style-type: none;
  display: block;
  padding-left: 30px;
  height: 100%;
  margin-bottom: 150px;
  list-style-type: none;
  padding: 0;
  margin-top: 20px;
}


.package-option li {
  font-size: 1rem;
  line-height: 1.8;
  display: block;
  width: 350px;
  color: #013B46;
  font-family: 'Rubik';
  font-weight: 400;
}


.package-button {
  display: inline-block;
  padding: 10px 20px;
  margin-top: 10px;
  color: #013B46;
  text-decoration: none;
  border-radius: 5px;
  text-align: center;
  border-style: solid;
  border-width: 1px;
  border-color: #013B46;
  z-index: 9999;
  font-size: 20px;
  font-family: 'Rubik';
  -webkit-tap-highlight-color: transparent; /* Убирает синий цвет на iPhone */

}

.package-button:hover {
  background-color: #373C58;
  color: #ffffff;
  border-color: #373C58;
}

.premium .package-button {
  color: #373C58; /* Белый цвет текста для премиум-пакета */
  font-weight: 400;
  font-family: 'Rubik';
  background-color: #4ae9e4; /* Цвет фона для премиум-пакета */
  border-color: #4ae9e4; /* Цвет рамки для премиум-пакета */
}

.premium .package-button:hover {
  background-color: #373C58;
  color: #ffffff;
  border-color: #373C58;
  font-family: 'Rubik';
}


.package-option-btns {
  position: absolute;
  bottom:20px;
  left: 50%;
  transform: translateX(-50%);
  display: 'flex';
  flex-direction: 'column';
  justify-content: 'center';
  align-items: 'center';
  margin-bottom: 100;

}

.package-price-container{
  padding-top: 40px;
}

.package-price-container h1{
  color: #013B46;
  font-family: 'Rubik';

}

.package-option h1 {
  position: absolute;
  bottom: 130px;
  left: 50%;
  transform: translateX(-50%);
  color: black;
}

.package-option h3 {
  position: absolute;
  bottom:150px;
  left: 50%;
  transform: translateX(-50%);
  
  color: #013B46;
  font-size: 25px;
}



.package-option h1 {
  color: #013B46;
  text-align: center;
  margin-top: 20px;
}

.button-left {
  text-align: center;
  padding-top: 110px;
}

.button-right {
  text-align: center;
  align-items: flex-end;
}

.package-option-icon-border {
  border: solid 1px #013B46;
  margin: 20px;
  width: 99%;
}

@media screen and (max-width: 600px) {
  .packages-container h2{
    font-size: 20px; 
    width: 100%;
    
  }

  .package-direction {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}