.video-uploader-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0;

}

.video-grid {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0;
}

.custom-file-input-video {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    /* background-color: #4ae9e4; */
    border: 2px dashed #003B46;
    padding: 10px;
    border-radius: 10px;
    transition: background-color 0.3s;
}

.custom-file-input-video:hover {

    /* background-color: #4ae9e4; */

}

.video-container {
    background-color: #dcdce2;
    margin: 0 auto;
    border-radius: 8px;
}

.video-container video {
    width: 100px;
    height: 100px;
    border: 1px solid white;
}



@media only screen and (max-width: 600px) {
    .video-container video {
        width: 75px;
        height: 100px;

    }
}