.page-not-found-container{
    background-color: #003B46;
    padding: 50px;
    height:25vw;
}
.page-not-found-details{
    text-align: center;
    background-color: #dcdce2;
    border-radius: 8px;
    padding: 20px;
    font-size: 20px;
}

@media only screen and (max-width: 1000px) {
.page-not-found-container{
    height: 100%;
}
}