.cookie-consent-container {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: rgba(255, 255, 255, 1);
  /* Белый фон с прозрачностью */
  padding: 20px;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.6);
  z-index: 9999;
}

.cookie-consent {
  text-align: center;

}

.cookie-consent a{
  font-size: 18px;
}

.cookie-consent p {
  margin-bottom: 10px;
}

.cookie-consent button {
  background-color: #07575B;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}

.cookie-consent button:hover {
  background-color: #003B46;
}

@media screen and (max-width: 600px) {
  .cookie-consent-container {
    width: 90%;
  }
}