.sign-in-form {
  position: relative;
  padding: 20px;
  background-color: #dcdce2;
  background-color: rgba(0, 0, 0, 0.5); 
 text-align: center;
 
}

.sign-in-form-vertical {
  display: flex;
  flex-direction: column;
  align-items: center;
}


.sign-in-form::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('../../Images/many_people.jpg'); /* Путь к вашему фоновому изображению */
  opacity: 0.5; /* Прозрачность изображения */
  background-size: auto; /* Returns to default sizing to allow for repeating */
  min-height: 100vh; /* Ensure it covers at least the screen height */

}


.sign-In-titel {
  position: relative;
  min-height: 600px;
  opacity: 0;
  animation: fadeIn 1s forwards;
  animation-delay: 0.5s;
}

.sign-in-input-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80%;
  opacity: 1;
  z-index: 9999;
}


.sign-in-form h4 {
  color: #fff;
  font-size: 47px;
  font-family: 'Rubik', sans-serif;
  font-weight: 800;
  text-align: center;
  opacity: 1;
  z-index: 9999;

}


.sign-in-forgot-password-container {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-wrap: wrap;
  margin-bottom: 20px;
  /* width: 380px; */
  margin-top: 20px;
  

}

.sign-in-forgot-password-link a {
  color: white;
  font-size: 25px;
  margin: 18px;

}

.btns-sign-in { 
  margin: 0 auto;
  display: flex;
  flex-direction: column; 
 } 

.btns-sign-in{ 
  font-size: 20px;
  color: "#013B46";
  font-family: 'Rubik', sans-serif;
  font-weight: 400;
   /* margin-top: 500px; */
} 

.sign-in-tabs {
  display: flex !important;
  flex-direction: row !important;
  padding: 20px;
}

.form-link {
  display: inline-block;
  margin-top: 10px;
}

.form-link p {
  margin: 0;
  font-size: 16px;
  color: #013B46;
}

.form-link p:hover {
  text-decoration: underline;
}

.temp-sign-title h4 {
  width: 500px;
  text-align: center;
}

@media only screen and (max-width: 600px) {
  .sign-in-form {
    padding: 0px;
  }
  
  .sign-in-forgot-password-link a {
    font-size: 18px;
    margin-top: 10px;
    text-align: center;
  }
}