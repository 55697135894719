.fixed-whatsapp-button {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: #25D366;
    color: white;
    border: none;
    border-radius: 50px;
    padding: 10px 10px;
    font-size: 15px;
    cursor: pointer;
    z-index: 1000;
  }

  .fixed-whatsapp-button-hebrew {
    position: fixed;
    bottom: 20px;
    left: 20px;
    background-color: #25D366;
    color: white;
    border: none;
    border-radius: 50px;
    padding: 10px 10px;
    font-size: 15px;
    cursor: pointer;
    z-index: 1000;
  }
  
  .fixed-whatsapp-button:hover {
    background-color: #1DA851;
  }
  
  @media only screen and (max-width: 500px) {
    .fixed-whatsapp-button {
    font-size: 15px;
    }
  }