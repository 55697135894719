.free-text-container {
    color: #013B46;
    line-height: 3;
}

.free-text-buttons {
    /* display: flex;
    flex-direction: column;
    text-align: center; */
    text-align: center;
}