

.contact-form {
  margin: 0 auto;
  max-width: 600px;
  padding: 20px;
  background-color: white; /* Цвет фона формы */
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.8), 0 8px 16px rgba(0, 0, 0, 0.1); /* Добавляет тень для формы */  
  
}

.contact-form h2 {
  color: #003B46;
  margin-left: 10px;
  text-align: center;
}


.contact-us_hebrew{
  padding-left: 0px;
}

.contact-input-group {
  margin-bottom: 20px;
}

.contact-btn-send{
  margin-bottom: 20px;

}

.contact-button {
  display: inline-block;
  padding: 10px 20px;
  margin-top: 10px;
  color: #003B46;
  text-decoration: none;
  border-radius: 5px;
  text-align: center;
  border-style: solid;
  border-width: 1px;
  border-color: #4ae9e4;
  z-index: 9999;
  font-size: 20px;
  font-family: 'Rubik';

}

@media only screen and (max-width: 600px) {
  .contact-form {
   text-align: justify;
   width: 300px;
  }
  .contact-form h2 {
   font-size: 20px !important;
   text-align: center;
  }

  .contact-button {
    padding: 10px 20px;
    margin-top: 10px;
    text-align: center;
    font-size: 20px;
  }
}
